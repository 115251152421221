import React from "react"

import MainTemplate from "../MainTemplate/MainTemplate"
import facebookButton from "../../images/facebookbutton.svg"
import villa3 from "../../images/villa-collis/villacollis-3.jpg"
import villa6 from "../../images/villa-collis/other/villacollis-6.jpg"
import Footer from "../../components/organisms/Footer/Footer"
import Hero from "../../components/organisms/Hero/Hero"

import {
  Grid,
  StyledGridItem,
  GridItemImages,
  StyledHeading,
  StyledImage,
  FacebookButton,
  FacebookIcon,
} from "./VillacollisTemplate.styles"

const VillacollisTemplate = ({ heroImage, sections }) => (
  <MainTemplate>
    <Hero title="Villa Collis - obiekt istniejący" images={heroImage} subpage />
    <Grid>
      <StyledHeading order={1}>
        Villa Collis to luksusowy i jednocześnie kameralny obiekt wypoczynkowy o
        bardzo wysokim standardzie.
      </StyledHeading>
      {sections.map((section, index) =>
        index % 2 ? (
          <StyledGridItem
            key={index}
            title={section.title}
            heading={section.heading}
            text={section.text}
            image={section.image}
            reversed
          />
        ) : (
          <StyledGridItem
            key={index}
            title={section.title}
            heading={section.heading}
            text={section.text}
            image={section.image}
          />
        )
      )}

      <StyledHeading order={4}>
        To coś więcej niż hotel - to miejsce, do którego zawsze chce się wracać.
      </StyledHeading>
      <GridItemImages>
        <StyledImage src={villa6} />
        <StyledImage src={villa3} />
      </GridItemImages>
    </Grid>
    <Footer />
    <FacebookButton>
      <FacebookIcon src={facebookButton} />
      <iframe
        title="facebook"
        src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fvillacollis%2F&tabs=timeline&width=300&height=450&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=1362498703933293"
        width="300"
        height="450"
        style={{ border: "none", overflow: "hidden" }}
        scrolling="no"
        frameborder="0"
        allowTransparency="true"
        allow="encrypted-media"
      ></iframe>
    </FacebookButton>
  </MainTemplate>
)

export default VillacollisTemplate
