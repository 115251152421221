import React from "react"
import { graphql } from "gatsby"
import VillacollisTemplate from "../templates/VillacollisTemplate/VillacollisTemplate"

const VillaCollis = ({ data }) => {
  const heroSources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ]

  const sections = [
    {
      id: 0,
      title: "O Obiekcie",
      heading: "Villa Collis",
      text:
        "W obiekcie znajduje się 10 luksusowych pokoi, większość z tarasami oraz balkonami z widokiem na lasy i jezioro. Dzięki wyjątkowej lokalizacji w Villa Collis można odnaleźć spokój i upajać się pięknem bieszczadzkich krajobrazów o każdej porze roku. Butikowy charakter obiektu, wysoka jakość usług, doskonałe wyposażenie i przede wszystkim malownicze położenie z dala od zatłoczonych miejscowości sprawiają, że obiekt cieszy się bardzo dużym zainteresowaniem. W ciągu trzech lat funkcjonowania Villa Collis wypracowała renomę, o czym świadczą same pozytywne opinie gości oraz wysokie obłożenie. Sukces prowadzonej działalności skłonił nas do podjęcia decyzji o rozbudowie obiektu do poziomu jakiego oczekuje od nas rynek.",
      image: data.villacollis1.childImageSharp.fluid,
    },
    {
      id: 1,
      title: "Charakter obiektu",
      heading: "Upajaj się pięknem bieszczadzkiej przyrody",
      text:
        "Villa Collis to najbardziej komfortowy, ekskluzywny i jednocześnie kameralny obiekt hotelowy w Bieszczadach zapewniający indywidualną obsługę i akceptujący szeroki zakres życzeń przebywających u nas Gości. To miejsce, gdzie można odnaleźć prawdziwy spokój i upajać się pięknem bieszczadzkiej przyrody o każdej porze roku, z tego też względu wszelkie atrakcje obiektu oraz restauracja dostępne są wyłącznie dla Gości hotelowych, a ich prywatność pilnie jest przez nas strzeżona. Zamknięty i intymny charakter obiektu, indywidualne, osobiste i jednostkowe podejście obsługi do każdego z naszych Gości zapewniają spokój i prywatność wszystkim, którzy właśnie tego pragną podczas pobytu poza domem. ",
      image: data.villacollis2.childImageSharp.fluid,
    },
    {
      id: 2,
      title: "Atrakcje obiektu",
      heading: "Luksusowy basen",
      text:
        "Villa Collis to obiekt całoroczny, wyposażony w luksusowy basen, z widokiem na Jezioro Solińskie. W basenie goście hotelu mogą korzystać z wielu atrakcji wodnych, w tym między innymi: masażerów, przeciwprądu, jacuzzi oraz brodziku dla dzieci z gejzerem. Basen posiada bezpośrednie wyjście na przestronny taras widokowy z leżakami i kącikiem kawowym oraz widokiem na las, jezioro i otaczające wzgórza. Woda basenowa jest w pełni ozonowana, dzięki czemu chroni przed wszelkimi podrażnieniami i alergiami.",
      image: data.villacollis3.childImageSharp.fluid,
    },
    {
      id: 3,
      title: "Atrakcje obiektu",
      heading: "Kompleks odnowy biologicznej",
      text:
        "Relaks dla ciała i głębokie odprężenie dla umysłu zapewnia wyjątkowy kompleks odnowy biologicznej, w skład którego wchodzi ekskluzywne SPA, salon masażu, salon kosmetyczny oraz fitness club. Bogata oferta strefy SPA&Wellness odpowiada na potrzeby nawet najbardziej wymagających gości. Salon masażu jest wyposażony w najwyższej jakości łóżka, a masaże wykonywane są przez wykwalifikowanych masażystów. Na życzenie gości zabiegi mogą zostać przeprowadzone w urokliwych sceneriach atrakcyjnie zaaranżowanego ogrodu z widokiem na jezioro. W salonie urody zapewniamy szeroką paletę zabiegów pielęgnacyjnych na twarz i ciało z wykorzystaniem najnowszych, ekskluzywnych kosmetyków.",
      image: data.villacollis4.childImageSharp.fluid,
    },
    {
      id: 4,
      title: "Atrakcje obiektu",
      heading: "Kompleks saun",
      text:
        "Obiekt jest wyposażony w kompleks saun, które stanowią doskonałą terapię dla duszy i ciała. Pozwalają rozgrzać obolałe mięśnie, łagodzą napięcia, rozluźniają i służą w walce z reumatyzmem, czy z trapiącymi niejednego z nas schorzeniami stawów. Goście mogą skorzystać z sauny fińskiej oraz łaźni rzymskiej, które zostały wykonane z dbałością o każdy szczegół, posiadają podgrzewane siedziska, a relaks ułatwiają aromatyczne olejki eteryczne oraz efekt rozgwieżdżonego nieba.",
      image: data.villacollis5.childImageSharp.fluid,
    },
    {
      id: 5,
      title: "Atrakcje obiektu",
      heading: "Restauracja",
      text:
        "Kameralna restauracja serwuje wykwintne dania kuchni regionalnej oraz europejskiej, przygotowane ze świeżych i lokalnych produktów. Aby dopieścić podniebienia gości, robimy własne wędliny ze sprawdzonego mięsa, wędzone na miejscu, własne ciasta, lody i desery, serwujemy lokalne sery i miody. Kolacje serwujemy również w formie bufetu grillowego w pięknej altanie. Dbamy również o zróżnicowaną ofertę naszego baru, w którym każdy znajdzie dla siebie coś dobrego, włącznie z bogatym zestawem bieszczadzkiego i słowackiego piwa oraz wysokiej jakości smacznych win.",
      image: data.villacollis6.childImageSharp.fluid,
    },
    {
      id: 6,
      title: "Atrakcje obiektu",
      heading: "Plac zabaw i rekreacja indywidualna",
      text:
        "Na terenie obiektu znajduje się również plac zabaw dla dzieci z boiskiem wielofunkcyjnym do gry w piłkę nożną, siatkówkę czy koszykówkę.",
      image: data.villacollis7.childImageSharp.fluid,
    },
    {
      id: 7,
      title: "Atrakcje obiektu",
      heading: "Altana i ogród",
      text:
        "Na terenie ogrodu znajduje się również przestronna, całoroczna altana ogrodowa dla potrzeb obsługi różnego rodzaju spotkań okolicznościowych.",
      image: data.villacollis8.childImageSharp.fluid,
    },
    {
      id: 8,
      title: "Atrakcje obiektu",
      heading: "Lądowisko dla helikopterów",
      text:
        "Aby zapewnić naszym Gościom maksymalny komfort dotarcia do naszego obiektu, oddaliśmy do ich dyspozycji lądowisko dla helikopterów.",
      image: data.villacollis9.childImageSharp.fluid,
    },
    {
      id: 9,
      title: "Atrakcje obiektu",
      heading: "Przystań wodna",
      text:
        "Teren inwestycyjny graniczy bezpośrednio z tzw. pasem zalewowym, posiada dostęp do Zalewu Solińskiego. U jego brzegów, w jednej z malowniczych zatok rozległego jeziora posiadamy przystań wodą, gdzie można wypożyczyć różnego rodzaju sprzęt pływający i korzystać w pełni z wodnych atrakcji z możliwością szybkiego przemieszczania się po wodach całego akwenu.",
      image: data.villacollis10.childImageSharp.fluid,
    },
  ]

  return <VillacollisTemplate heroImage={heroSources} sections={sections} />
}

export const query = graphql`
  {
    mobileImage: file(name: { eq: "villacollis-hero-mobile" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    desktopImage: file(name: { eq: "villacollis-hero-desktop" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    allFile(
      filter: { relativeDirectory: { eq: "villa-collis" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 1524) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
    villacollis1: file(name: { eq: "villacollis-1" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    villacollis2: file(name: { eq: "villacollis-2" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    villacollis3: file(name: { eq: "villacollis-3" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    villacollis4: file(name: { eq: "villacollis-4" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    villacollis5: file(name: { eq: "villacollis-5" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    villacollis6: file(name: { eq: "villacollis-6" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    villacollis7: file(name: { eq: "villacollis-7" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    villacollis8: file(name: { eq: "villacollis-8" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    villacollis9: file(name: { eq: "villacollis-9" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    villacollis10: file(name: { eq: "villacollis-91" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default VillaCollis
