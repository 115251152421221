import styled from "styled-components"

import GridItem from "../../components/molecules/GridItem/GridItem"

export const Grid = styled.div`
    padding-top: 100px;
    
    ${({ theme }) => theme.mq.desktop} {
        display: grid;
    }
`

export const StyledGridItem = styled(GridItem)`
    margin: 64px 0;
    padding: 0 32px;

    ${({ theme }) => theme.mq.desktop} {
        padding: 100px 0;
        margin: ${({ reversed }) => reversed ? "64px auto 0 0" : "64px 0 0 auto"};
        width: 90%;
    }
`

export const GridItemImages = styled.div`
    display: grid;
    grid-row: 6;

    ${({ theme }) => theme.mq.desktop}{
        grid-template-columns: 1fr 1fr;
    } 
`

export const StyledHeading = styled.h2`
    text-align: center;
    padding: 0 32px;
    grid-row: ${({order}) => order};

    ${({ theme }) => theme.mq.desktop} {
        width: 40%;
        margin: 0 auto;
        font-size: 3.2rem;
    }
`

export const StyledImage = styled.img`
    width: 100%;
`

export const FacebookButton = styled.div`
    position: fixed;
    right: 0;
    top: 25%;
    z-index: 1000000000;
    transform: translateX(300px);
    display: flex;
    transition: all 0.4s ease-in-out;
    cursor: pointer;

    &:hover {
        transform: translateX(0px);
    }
`

export const FacebookIcon = styled.img`
    height: 45px;
`